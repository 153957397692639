import { css } from "@emotion/react";

export const spacer = css`
  display: inline-block;
`;

export const heightDisplay = css`
  display: block;
`;

export const height = {
  quarter: css`
    height: 0.25rem;
  `,
  half: css`
    height: 0.5rem;
  `,
  one: css`
    height: 1rem;
  `,
  oneQuarter: css`
    height: 1.25rem;
  `,
  oneHalf: css`
    height: 1.5rem;
  `,
  double: css`
    height: 2rem;
  `,
  doubleHalf: css`
    height: 2.5rem;
  `,
  triple: css`
    height: 3rem;
  `,
  tripleHalf: css`
    height: 3.5rem;
  `,
  quad: css`
    height: 4rem;
  `,
};

export const width = {
  quarter: css`
    width: 0.25rem;
  `,
  half: css`
    width: 0.5rem;
  `,
  one: css`
    width: 1rem;
  `,
  oneQuarter: css`
    width: 1.25rem;
  `,
  oneHalf: css`
    width: 1.5rem;
  `,
  double: css`
    width: 2rem;
  `,
  doubleHalf: css`
    width: 2.5rem;
  `,
  triple: css`
    width: 3rem;
  `,
  tripleHalf: css`
    width: 3.5rem;
  `,
  quad: css`
    width: 4rem;
  `,
};

export const type = {
  internal: css`
    ${height.one}
  `,
  section: css`
    ${height.oneHalf}
  `,
  page: css`
    ${height.double}
  `,
  footer: css`
    ${height.quad}
  `,
};
