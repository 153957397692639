import { css, type Theme } from "@emotion/react";
import type { ColorProfileTheme } from "aviary-tokens/dist/types";

import { animations } from "@styles";

export const linkStyling = css`
  ${animations.transition()};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const hoverUnderline = css`
  &:hover {
    text-decoration: underline;
  }
`;

export const noUnderline = css`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const externalIcon = css`
  margin-left: 0.25rem;
  margin-right: 0.125rem;
`;

export const disabled = (theme: Theme) => css`
  color: ${theme.disabled.text};
  cursor: not-allowed;

  &:hover,
  &:focus,
  &:active {
    color: ${theme.disabled.text};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const light = (theme: Theme) => css`
  color: ${theme.light.backgroundBase};

  &:hover {
    color: ${theme.light.linkMutedHover};
  }
  &:active {
    color: ${theme.light.linkMutedHover};
    text-decoration: none;
  }
`;

export const lightMuted = (theme: Theme) => css`
  color: ${theme.light.linkMutedBase};
  text-decoration-color: ${theme.light.linkMutedBase};

  &:hover {
    color: ${theme.light.linkMutedHover};
    text-decoration-color: ${theme.light.linkMutedHover};
  }
`;

export const themedColor = (theme: ColorProfileTheme) => css`
  color: ${theme.textBase};

  &:hover {
    color: ${theme.textHover};
  }
  &:active {
    color: ${theme.textActive};
    text-decoration: none;
  }
`;

export const noStyleButton = css`
  padding: 0;
`;
